<template>
    <div class="companyDetail">
        <el-page-header @back="goBack" content="企业详情" >
        </el-page-header>
        <div class="company">
            <div class="compInfo">
                <div class="img">
                    企业
                </div>
                <div class="info">
                    <h3>{{comList.companyName}}</h3>
                    <span class="infos">
                        <span>{{comList.province}}/{{comList.city}}</span>|
                        <span>{{comList.companyTradeName}}</span>|
                        <span>{{comList.companyScale}}</span>
                    </span>
                </div>
            </div>
        </div>
        <headLine msg='招聘岗位'></headLine> 
        <div class="positionBox">
            <ul v-if="positionList.length>0">
                <li v-for="(v,i) in positionList" :key="i">
                    <h4>{{v.jobName}}<span>{{v.salary}}</span></h4>
                    <div class="info">
                        <span>{{v.workCity}}</span> |
                        <span>招聘{{v.recruitsNum}}人</span>
                    </div>
                    <div class="infos" @click="getPositionModel(v.jobIntroduce)">
                        岗位要求：{{v.jobIntroduce}}
                    </div>
                </li> 
            </ul>
            <blankPage v-else msg='该企业还未添加招聘岗位~'></blankPage>
        </div>
        <headLine msg='企业介绍'></headLine>
        <p class="companyIntroduce"><span>企业介绍：</span>{{comList.companyIntroduce}}</p> 
        <p class="companyIntroduce"><span>企业对接人：</span>{{comList.contactsName}}</p>
        <p class="companyIntroduce"><span>企业联系电话：</span>{{comList.phoneNum}}</p>
        <p class="companyIntroduce"><span>企业网站：</span>{{comList.companyWebsite}}</p> 
        <p class="companyIntroduce"><span>企业组织机构代码：</span>{{comList.orgCode}}</p> 
        <el-dialog
            title="岗位详情"
            :visible.sync="positionModel"
            width="38%"
            >
            <div class="positionModel">   
                <ul>
                    <li v-html="detailText"></li>       
                </ul>   
            </div>         
        </el-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
           comList:{},
           positionList:[1,2,3],
           positionModel:false,
           detailText:''
        }
    },
    methods: {
        getPositionModel(jobIntroduce){
            this.positionModel=!this.positionModel
            this.detailText=jobIntroduce
        },
        goBack(){
            if(this.$route.params.Comestore){
                this.$router.push({path:"/companyStore"})
            }else{
                this.$router.push({path:"/review",query:{id:this.$route.params.id}})
            }
            
        },  
    },
    mounted() {
        this.comList=this.$route.params.row
        this.positionList=this.comList.jobList
    },
    
}
</script>
<style lang="scss" scoped>
@import '../../assets/css/companyDetail.scss';
</style>